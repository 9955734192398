import { SolanaAdapter } from "@reown/appkit-adapter-solana/vue";
import { solana } from "@reown/appkit/networks";
import { createAppKit } from "@reown/appkit/vue";
import {
  HuobiWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TrustWalletAdapter,
} from "@solana/wallet-adapter-wallets";

let globalAppKit: ReturnType<typeof createAppKit> | null = null;

export function getGlobalAppKit() {
  if (globalAppKit !== null) {
    return globalAppKit;
  }

  const solanaWeb3JsAdapter = new SolanaAdapter({
    wallets: [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new HuobiWalletAdapter(),
      new TrustWalletAdapter(),
    ],
  });

  const projectId = useRuntimeConfig().public.REOWN_PROJECT_ID;
  if (!projectId) {
    throw new Error("VITE_REOWN_PROJECT_ID is not set");
  }

  // taken from https://walletguide.walletconnect.network/
  const metamaskWalletId =
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96";
  const trustWalletId =
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0";
  // this is not needed for Phantom as browser extension - that is displayed separately when detected
  const phantomWalletId =
    "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393";
  // const rainbowWalletID = "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369";

  const url = useRuntimeConfig().public.WHITELISTED_DOMAINS[0];

  globalAppKit = createAppKit({
    adapters: [solanaWeb3JsAdapter],
    networks: [solana],
    projectId,
    metadata: {
      // JANTODO
      name: "Evangelist",
      description: "",
      url: url,
      icons: [],
    },
    features: {
      email: false,
      socials: false,
    },
    themeMode: "light",
    allWallets: "HIDE",
    featuredWalletIds: [metamaskWalletId, trustWalletId, phantomWalletId],
    debug: true,
  });
  return globalAppKit;
}

// More components can listen to CONNECT_SUCCESS event on appkit, but only one component should react to it
export const globalAppKitLock: { lockingObject: null | object } = {
  lockingObject: null,
};
